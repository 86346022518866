import 'chart.js/dist/chart';
import 'chartjs-chart-box-and-violin-plot/build/Chart.BoxPlot.esm';
import { jsPDF } from "jspdf";

window.TS.initDownloadGraphBtn = function() {
  $(document).on('click', '.download_graph', function() {
    var graphId = $(this).data('graph');
    var chartCanvas = document.getElementById(graphId);
    var format = $(this).data('format');
    var filename = $(this).attr('download');
    if (format === 'png') {
      $(this).attr('href', chartCanvas.toDataURL('image/png'));
    } else {
      // Add white background
      destinationCanvas = document.createElement('canvas');
      destinationCanvas.width = chartCanvas.width;
      destinationCanvas.height = chartCanvas.height;
      destCtx = destinationCanvas.getContext('2d');
      //create a rectangle with the desired color
      destCtx.fillStyle = '#FFFFFF';
      destCtx.fillRect(0, 0, chartCanvas.width, chartCanvas.height);
      //draw the original canvas onto the destination canvas
      destCtx.drawImage(chartCanvas, 0, 0);

      if (format === 'jpeg') {
        $(this).attr('href', destinationCanvas.toDataURL('image/jpeg', 1));
      } else if (format === 'pdf') {
        var imgData = destinationCanvas.toDataURL('image/jpeg', 1);
        var pdf = new jsPDF('l', 'mm', 'a4');
        var imgWidth = 280; // based on A4 page landscape size
        var imgHeight = (destinationCanvas.height * imgWidth) / destinationCanvas.width;
        pdf.addImage(imgData, 'JPEG', 10, 10, imgWidth, imgHeight);
        pdf.save(filename);
      }
    }
  });
};

// DASHBOARD
window.TS.addLineGraph = function(id, options) {
  if (options === undefined) options = {};
  var element = document.getElementById(id);
  if (typeof element != 'undefined' && element != null) {
    var default_options = {
      scales: {
        yAxes: [
          {
            scaleLabel: {
              labelString: 'Frequency',
              display: true
            }
          }
        ]
      }
    };
    window.TS.graph[id].obj = new Chart(element, {
      type: 'line',
      data: window.TS.graph[id].data,
      options: $.extend(true, default_options, options)
    });
  }
};

window.TS.addStackedBarGraph = function(id, options) {
  if (options === undefined) options = {};
  var element = document.getElementById(id);
  if (typeof element != 'undefined' && element != null) {
    var default_options = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true
            },
            stacked: true,
            scaleLabel: {
              labelString: 'Frequency',
              display: true
            }
          }
        ],
        xAxes: [
          {
            stacked: true
          }
        ]
      }
    };
    window.TS.graph[id].obj = new Chart(element, {
      type: 'bar',
      data: window.TS.graph[id].data,
      options: $.extend(true, default_options, options)
    });
  }
};

window.TS.addBoxplot = function(id, options) {
  if (options === undefined) options = {};
  var element = document.getElementById(id);
  if (typeof element != 'undefined' && element != null) {
    var default_options = {
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: 20,
              suggestedMax: 55
            },
            scaleLabel: {
              labelString: 'Frequency',
              display: true
            }
          }
        ],
        xAxes: [
          {
            scaleLabel: {
              labelString: 'Groups',
              display: true
            }
          }
        ]
      }
    };
    window.TS.graph[id].obj = new Chart(element, {
      type: 'boxplot',
      data: window.TS.graph[id].data,
      options: $.extend(true, default_options, options)
    });
  }
};

window.TS.initDiagnosisType = function() {
  $('input[name="chart_type[]"]').on('change', function() {
    var graph_type = $('input[name="chart_type[]"]:checked').val();
    window.TS.graph.highest_diagnosis_graph.obj.data = window.TS.graph[graph_type].data;
    window.TS.graph.highest_diagnosis_graph.obj.update();
  });
};
