const updatedQuestions = (eye) => {
  return [
    `oct_${eye}_fovea_thickness`,
    `oct_${eye}_sub_fovea_choroid_thickness`,
    `subretinal_fluid_${eye}`,
    `oct_${eye}_subretinal_fluid_thickness`,
    `intraretinal_fluid_${eye}`,
    `oct_${eye}_central_subfield_thickness`,
    `subretinal_haemorrhage_${eye}`,
    `subretinal_haemorrhage_new_${eye}`,
    `subrpe_haemorrhage_${eye}`,
    `subrpe_haemorrhage_new_${eye}`,
    `shrm_${eye}`,
    `shrm_defined_${eye}`,
    `oct_${eye}_shrm_thickness`,
    `double_layer_sign_${eye}`,
    `polypoidal_vasculopathy_${eye}`,
    `extrafoveal_choroidal_neovascularisation_${eye}`,
    `hyperreflective_dots_${eye}`,
    `rpe_tear_${eye}`,
    `ped_${eye}`,
    `oct_${eye}_ped_thickness`,
    `subrpe_fibrosis_${eye}`,
    `outer_retinal_tubulation_${eye}`,
    `pre_choroidal_clefts_${eye}`,
    `geographical_atrophy_${eye}`,
    `geographical_atrophy_foveal_${eye}`,
    `drusen_${eye}`,
    `drusen_${eye}_specified`,
    `reticular_pseudo_drusen_${eye}`,
    `amd_status_${eye}`,
    `amd_status_${eye}_early_status`,
    `amd_status_${eye}_late_status`,
    `amd_status_${eye}_wet_status`,
    `amd_status_${eye}_dry_status`,
  ];
};

export const getSubjectIdAndVisit = () => {
  // /subjects/1/visit/2
  const path = window.location.pathname;
  const pathParts = path.split('/');

  // Replace all non-digit characters with empty string
  const sanitizeInput = (input) => {
    return input.replace(/\D/g, '');
  };

  return {
    url: path,
    subjectId: sanitizeInput(pathParts[2] || ''),
    visitNumber: sanitizeInput(pathParts[4] || ''),
  };
};

const updateFields = (columns, data) => {
  if (!data) return;

  columns.forEach(function (column) {
    const fieldName = column;
    const fieldValue = data[column];

    // select multiple checkboxes case as well as just single checkbox /radio button
    const fields = document.querySelectorAll(
      `[name="case_report[${fieldName}]"], [name="case_report[${fieldName}][]"]`
    );

    fields.forEach((field) => {
      if (field.type === 'checkbox') {
        if (Array.isArray(fieldValue)) {
          field.checked = fieldValue.includes(field.value);
        } else {
          field.checked = fieldValue === true;
        }
      } else if (field.type === 'radio') {
        field.checked = field.value === String(fieldValue);
      } else if (field.value === '') {
        field.value = fieldValue;
      }
    });
  });
};

const fetchData = async (visitNumber) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]');
  if (!csrfToken) {
    throw new Error('CSRF token not found. Please refresh the page and try again.');
  }

  const { subjectId } = getSubjectIdAndVisit();

  const response = await fetch(`/api/v1/subjects/${subjectId}/visits/${visitNumber}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.getAttribute('content'),
    },
  });

  if (!(response.ok || response.status === 304)) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

const dataExists = (columns, data, eye) => {
  const answersDontExist = columns.every((column) => Boolean(data[column]) === false);

  if (answersDontExist) {
    document.getElementById(`no_${eye}_oct_res_err`).classList.remove('hidden');
    return false;
  }
  return true;
};
const handleButtonClick = async function () {
  try {
    const eye = this.id.includes('left') ? 'left' : 'right';
    document.getElementById(`no_${eye}_visit_options_err`).classList.add('hidden');
    const select = document.getElementById(`${eye}_visit_select`);
    if (!select?.value) {
      document.getElementById(`no_${eye}_visit_options_err`).classList.remove('hidden');
      return;
    }

    const data = await fetchData(select.value);

    if (dataExists(updatedQuestions(eye), data.data, eye)) {
      updateFields(updatedQuestions(eye), data.data);
      document.getElementById(`reset_${eye}_oct_fields`).classList.remove('hidden');
      TS.initConditional();
    }
    // recall conditional to display conditional fields based on new values
  } catch (error) {
    console.error('Error fetching or updating data:', error);
  }
};

const handleResetButtonClick = async function () {
  const eye = this.id.includes('left') ? 'left' : 'right';
  updatedQuestions(eye).forEach((fieldName) => {
    const fields = document.querySelectorAll(
      `[name="case_report[${fieldName}]"], [name="case_report[${fieldName}][]"]`
    );

    fields.forEach((field) => {
      if (field.type === 'checkbox') {
        field.checked = false;
      } else if (field.type === 'radio') {
        field.checked = false;
      } else {
        field.value = '';
      }
    });
  });
};
export const copyOldVisit = () => {
  const { subjectId } = getSubjectIdAndVisit();
  if (!subjectId) {
    console.error('ID not found in URL.');
    return;
  }

  ['left', 'right'].forEach((eye) => {
    const button = document.getElementById(`cp_prev_${eye}_oct_res`);

    if (!button) {
      console.error(`Button for ${eye} eye not found.`);
      return;
    }

    const resetButton = document.getElementById(`reset_${eye}_oct_fields`);

    resetButton.removeEventListener('click', handleResetButtonClick);
    // Remove existing event listener if any
    button.removeEventListener('click', handleButtonClick);

    // Add new event listener
    button.addEventListener('click', handleButtonClick);
    resetButton.addEventListener('click', handleResetButtonClick);
  });
};
