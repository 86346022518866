import './jquery.slimscroll';

// Boxed Page
var boxed_page = function(page_boxed) {
  if (page_boxed === true) {
    $('.page-container').addClass('container');
  }
};

// Fixed Header
var fixed_header = function(page_header_fixed) {
  if (page_header_fixed === true) {
    $('body').addClass('page-header-fixed');
  }
};

// Fixed Sidebar
var fixed_sidebar = function(page_sidebar_fixed) {
  if (
    $('body').hasClass('page-sidebar-fixed') &&
    page_sidebar_fixed === false
  ) {
    page_sidebar_fixed = true;
  }

  if (page_sidebar_fixed === true) {
    $('body').addClass('page-sidebar-fixed');
    $('#fixed-sidebar-toggle-button').removeClass(
      'icon-radio_button_unchecked'
    );
    $('#fixed-sidebar-toggle-button').addClass('icon-radio_button_checked');
  }

  var fixed_sidebar_toggle = function() {
    $('body').toggleClass('page-sidebar-fixed');
    if ($('body').hasClass('page-sidebar-fixed')) {
      page_sidebar_fixed = true;
    } else {
      page_sidebar_fixed = false;
    }
  };

  $('#fixed-sidebar-toggle-button').on('click', function() {
    fixed_sidebar_toggle();
    $(this).toggleClass('icon-radio_button_unchecked');
    $(this).toggleClass('icon-radio_button_checked');
    return false;
  });
};

// Collapsed Sidebar
var collapsed_sidebar = function(page_sidebar_collapsed) {
  if (page_sidebar_collapsed === true) {
    $('body').addClass('page-sidebar-collapsed');
  }

  var collapsed_sidebar_toggle = function() {
    $('body').toggleClass('page-sidebar-collapsed');
    if ($('body').hasClass('page-sidebar-collapsed')) {
      page_sidebar_collapsed = true;
    } else {
      page_sidebar_collapsed = false;
    }
    $('.page-sidebar-collapsed .page-sidebar .accordion-menu').on(
      {
        mouseenter: function() {
          $('.page-sidebar').addClass('fixed-sidebar-scroll');
        },
        mouseleave: function() {
          $('.page-sidebar').removeClass('fixed-sidebar-scroll');
        }
      },
      'li'
    );
  };

  $('.page-sidebar-collapsed .page-sidebar .accordion-menu').on(
    {
      mouseenter: function() {
        $('.page-sidebar').addClass('fixed-sidebar-scroll');
      },
      mouseleave: function() {
        $('.page-sidebar').removeClass('fixed-sidebar-scroll');
      }
    },
    'li'
  );
  $('#collapsed-sidebar-toggle-button').on('click', function() {
    collapsed_sidebar_toggle();
    return false;
  });
};

var small_screen_sidebar = function() {
  if (
    $(window).width() < 768 &&
    $('#fixed-sidebar-toggle-button').hasClass('icon-radio_button_unchecked')
  ) {
    $('#fixed-sidebar-toggle-button').click();
  }
  $(window).on('resize', function() {
    if (
      $(window).width() < 768 &&
      $('#fixed-sidebar-toggle-button').hasClass('icon-radio_button_unchecked')
    ) {
      $('#fixed-sidebar-toggle-button').click();
    }
  });
  $('#sidebar-toggle-button').on('click', function() {
    $('body').toggleClass('page-sidebar-visible');
    return false;
  });
  $('#sidebar-toggle-button-close').on('click', function() {
    $('body').toggleClass('page-sidebar-visible');
    return false;
  });
};

// Accordion menu
var accordion_menu = function(
  submenu_opacity_animation,
  submenu_animation_speed
) {
  var select_sub_menus = $('.page-sidebar li:not(.open) .sub-menu'),
    active_page_sub_menu_link = $('.page-sidebar li.active-page > a');

  // Hide all sub-menus
  select_sub_menus.hide();

  if (submenu_opacity_animation === false) {
    $('.sub-menu li').each(function(i) {
      $(this).addClass('animation');
    });
  }

  // Accordion
  $('.accordion-menu').on('click', 'a', function() {
    var sub_menu = $(this).next('.sub-menu'),
      parent_list_el = $(this).parent('li'),
      active_list_element = $('.accordion-menu > li.open'),
      show_sub_menu = function() {
        sub_menu.slideDown(submenu_animation_speed);
        parent_list_el.addClass('open');
        if (submenu_opacity_animation === true) {
          $('.open .sub-menu li').each(function(i) {
            var t = $(this);
            setTimeout(function() {
              t.addClass('animation');
            }, (i + 1) * 15);
          });
        }
      },
      hide_sub_menu = function() {
        if (submenu_opacity_animation === true) {
          $('.open .sub-menu li').each(function(i) {
            var t = $(this);
            setTimeout(function() {
              t.removeClass('animation');
            }, (i + 1) * 5);
          });
        }
        sub_menu.slideUp(submenu_animation_speed);
        parent_list_el.removeClass('open');
      },
      hide_active_menu = function() {
        $('.accordion-menu > li.open > .sub-menu').slideUp(
          submenu_animation_speed
        );
        active_list_element.removeClass('open');
      };

    if (sub_menu.length && !$('body').hasClass('page-sidebar-collapsed')) {
      if (!parent_list_el.hasClass('open')) {
        if (active_list_element.length) {
          hide_active_menu();
        }
        show_sub_menu();
      } else {
        hide_sub_menu();
      }

      return false;
    }
    if (sub_menu.length && $('body').hasClass('page-sidebar-collapsed')) {
      return false;
    }
  });

  if ($('.active-page > .sub-menu').length) {
    active_page_sub_menu_link.click();
  }
};



$(document).on('turbolinks:load', function() {
  'use strict';

  // Options
  var submenu_animation_speed = 100,
    submenu_opacity_animation = true, // set to "false" to remove opacity animation
    page_boxed = false,
    page_sidebar_fixed = false,
    page_sidebar_collapsed = false,
    page_header_fixed = false;

  // Slimscroll
  $('.page-sidebar-inner')
    .slimScroll({height: '100%'})
    .mouseover();

  boxed_page(page_boxed);
  fixed_header(page_header_fixed);
  fixed_sidebar(page_sidebar_fixed);
  collapsed_sidebar(page_sidebar_collapsed);
  small_screen_sidebar();
  accordion_menu(submenu_opacity_animation, submenu_animation_speed);

  // Slimscroll
  $('.slimscroll').slimScroll();

  // Uniform
  var checkBox = $(
    'input[type=checkbox]:not(.js-switch,.no-uniform), input[type=radio]:not(.no-uniform)'
  );
  if (checkBox.length > 0) {
    checkBox.each(function() {
      $(this).uniform();
    });
  }
});
