//// FULL RELOAD WHEN JS ERROR

// If a JS error occurs in the browser, the app can be left in
// a bad state depending on how badly the JS console decides to crash.
// Subsequent errors can continue to be triggered because the page is
// never reloaded - so force a full page reload on the next page visit.
window.TS.jsErrorHasOccurred = false;

window.onerror = function() {
  window.TS.jsErrorHasOccurred = true;
};

document.addEventListener('turbolinks:before-visit', function() {
  if (window.TS.jsErrorHasOccurred == true) {
    event.preventDefault(); // Cancel the turbolinks request
    window.location.href = event.data.url; // Do a regular page visit to clear the JS console
  }
});

//// Record if Jquery has been loaded
if (typeof jQuery == 'undefined') {
  window.TS.jquery_loaded = false;
  console.log(window.location.pathname);
  if (
    window.location.pathname !== '/' &&
    window.location.pathname !== '/sign_in' &&
    window.location.pathname !== '/no_jquery'
  ) {
    window.location = '/no_jquery';
  }
} else {
  window.TS.jquery_loaded = true;
}
