import SignaturePad from "signature_pad/dist/signature_pad.min.js";
function resizeCanvas(canvas) {
  var ratio =  Math.max(window.devicePixelRatio || 1, 1);
  canvas.width = canvas.offsetWidth * ratio;
  canvas.height = canvas.offsetHeight * ratio;
  canvas.getContext("2d").scale(ratio, ratio);
}

$(document).on('turbolinks:load', function() {
var canvas = document.querySelector("canvas");
if (canvas){
  canvas.height = canvas.offsetHeight;
  canvas.width = canvas.offsetWidth;
  window.onresize = resizeCanvas(canvas);
  resizeCanvas(canvas);
  let signature_pad = new SignaturePad(canvas);
  canvas.addEventListener("click", function(event) {
    if ( signature_pad.isEmpty() ) {
      $('.signature_pad_input').val("");
      console.log("saving balnak")
    } else {
      console.log("saving value auto")
      $('.signature_pad_input').val(signature_pad.toDataURL());
    }
});
  $('.signature_pad_clear').click(function(e) { 
    signature_pad.clear()
    $('.signature_pad_input').val("");
    e.stopPropagation();
  });
  $('.signature_pad_save').click(function(event) { 
    if (signature_pad.isEmpty()){
      alert('Please sign in this box by using a mouse or touch-pad to confirm your signature');
      event.preventDefault();
    } else {
      console.log("saved to hidden field")
      $('.signature_pad_input').val(signature_pad.toDataURL());
    }
    event.stopPropagation();
  });
}
});