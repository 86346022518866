import { getSubjectIdAndVisit } from './copy_old_visit';

const fetchData = async (eye) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]');
  if (!csrfToken) {
    throw new Error('CSRF token not found. Please refresh the page and try again.');
  }

  const { subjectId, visitNumber } = getSubjectIdAndVisit();

  const response = await fetch(`/api/v1/subjects/${subjectId}/${eye}/visit_options`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken.getAttribute('content'),
    },
  });

  if (!(response.ok || response.status === 304)) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
};

const setOptions = async (eye) => {
  const select = document.getElementById(`${eye}_visit_select`);
  if (!select) {
    console.error(`select for ${eye} eye not found.`);
    return;
  }
  const res = await fetchData(eye);
  if (res.length === 0) {
    const button = document.getElementById(`cp_prev_${eye}_oct_res`);
    button.disabled = true;
    return;
  }
  // Check if the select box has any existing options
  if (select.options.length === 0) {
    res.forEach((optionValue) => {
      const option = document.createElement('option');
      option.value = optionValue;
      option.textContent = optionValue;
      select.appendChild(option);
    });

    if (!select.value) {
      select.value = res[res.length - 1];
    }
  }
};
export const setVisitOptions = () => {
  const { subjectId } = getSubjectIdAndVisit();
  if (!subjectId) {
    console.error('ID not found in URL.');
    return;
  }

  ['left', 'right'].forEach((eye) => {
    setOptions(eye);
  });
};
