// RESET OBJECTS BEFORE CACHE
window.TS.resetDatatables = function() {
  dataTable = $($.fn.dataTable.tables(true)).DataTable();
  if (dataTable != null) {
    dataTable.destroy();
  }
  dataTable = null;
};

window.TS.resetSmartSelect = function() {
  if ($('.smart-select2').length == 0) return;
  $('.smart-select2').select2('destroy');
};

window.TS.resetCleaveObjects = function() {
  if (window.TS.cleaveObjects !== null && window.TS.cleaveObjects !== undefined) {
    window.TS.cleaveObjects.destroy();
  }
};

document.addEventListener('turbolinks:before-cache', function() {
  window.TS.resetDatatables();
  window.TS.resetSmartSelect();
  window.TS.resetCleaveObjects();
});
